import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Country, State } from "country-state-city";
import "./App.css";

import toast, { Toaster } from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";

import { addDoc, collection } from "firebase/firestore";
import { db } from "./firebaseConfig";

const Enclave = () => {
  // const [isChecked, setIsChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedExp, setSelectedExp] = useState(null);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [skills, setSkills] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [githubProfile, setGithubProfile] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setFormSuccessfullySubmitted] = useState(false);


  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const textRegex = /^[a-zA-Z\s]+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formFields = [
      { field: selectedCountry, message: "Please select your country" },
      { field: selectedState, message: "Please select your state" },
      { field: fullName, message: "Please enter your full name" },
      { field: lastName, message: "Please enter your last name" },
      { field: selectedGender, message: "Please select your gender" },
      { field: email, message: "Please enter your email address" },
      { field: mobileNumber, message: "Please enter your mobile number" },
      { field: jobTitle, message: "Please enter your current/past job title" },
      {
        field: linkedinProfile,
        message: "Please enter your LinkedIn profile URL",
      },
      { field: githubProfile, message: "Please enter your GitHub profile URL" },
      { field: selectedExp, message: "Please select your years of experience" },
      {
        field: selectedSpecialization,
        message: "Please select your specialization",
      },
      { field: skills.length > 0, message: "Please add at least one skill" },
    ];

    for (const { field, message } of formFields) {
      if (!field) {
        toast.error(message);
        return;
      }
    }

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (isNaN(mobileNumber) || mobileNumber.length < 10) {
      toast.error("Please enter a valid mobile number");
      return;
    }

    if (!textRegex.test(fullName) || !textRegex.test(lastName)) {
      toast.error("Name fields should contain only alphabetic characters");
      return;
    }

    setIsSubmitting(true);

    try {
      const formData = {
        PersonalInfo: {
          FullName: fullName,
          LastName: lastName,
          Gender: selectedGender.value,
          Email: email,
          MobileNumber: mobileNumber,
        },
        Location: {
          Country: selectedCountry.label,
          State: selectedState.label,
        },
        ProfessionalInfo: {
          JobTitle: jobTitle,
          LinkedinProfile: linkedinProfile,
          GithubProfile: githubProfile,
          Experience: selectedExp,
          Specialization: selectedSpecialization.value,
        },
        Skills: skills,
      };

      await addDoc(collection(db, "applications"), formData);
      setIsSubmitting(false);
      await setFormSuccessfullySubmitted(true); // Ensure this is completed
      toast.success("Form submitted successfully!");
      setIsFormDisabled(true);
    } catch (error) {
      setIsSubmitting(false);
      toast.error("An error occurred while submitting the form.");
      console.error("Error adding document: ", error);
    }
  };

  useEffect(() => {
    console.log("Is form disabled?", isFormDisabled);
  }, [isFormDisabled]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "," || e.key === "Enter") {
      e.preventDefault();
      if (inputValue.trim() !== "") {
        setSkills([...skills, inputValue.trim()]);
        setInputValue("");
      }
    }
  };

  const removeSkill = (index) => {
    if (isFormDisabled) return;
    const newSkills = [...skills];
    newSkills.splice(index, 1);
    setSkills(newSkills);
  };

  const countryOptions = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }));

  useEffect(() => {
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry.value);
      setStateOptions(
        states.map((state) => ({
          value: state.isoCode,
          label: state.name,
        }))
      );
    }
  }, [selectedCountry]);

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  const handleRadioChange = (exp) => {
    setSelectedExp(exp);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--bgFormEls)",
      borderColor: state.isFocused ? "var(--red)" : "var(--borderFormEls)",
      color: "var(--white)",
      padding: "15px 10px",
      boxShadow: state.isFocused ? "0 0 0 1px var(--red)" : null,
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--bgFormEls)",
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--bgFormEls)",
      padding: 0, // Remove padding to eliminate white space at the top and bottom
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--bgFormElsFocus)"
        : "var(--bgFormEls)",
      color: "var(--white)",
      "&:hover": {
        backgroundColor: "var(--bgFormElsFocus)",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--white)",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "var(--white)",
    }),
    input: (provided) => ({
      ...provided,
      color: "var(--white)",
    }),
  };

  const specializationOptions = [
    "Still Deciding (not-sure-yet)",
    "Front-end Engineer",
    "Back-end Engineer",
    "Developer Relations",
    "Developer Advocate",
    "Full-stack Developer",
    "Mobile Developer",
    "Product Designer",
    "Product Manager",
    "QA Engineer",
    "Cloud Engineer",
    "Technical Support",
    "Community Manager",
    "Technical Recruiter",
    "Technical Writer",
    "Project Manager",
    "Solidity Developer",
    "Solutions Architect",
    "IoT Engineer",
  ].map((role) => ({ value: role, label: role }));

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  // const resetForm = () => {
  //   setSelectedCountry(null);
  //   setSelectedState(null);
  //   setIsChecked(false);
  // };

  return (
    <div className="container">
      <Toaster />
      <form className="my-form" onSubmit={handleSubmit}>
        <center>
          <h1>TEN</h1>
        </center>
        <ul>
          <>
            <li>
              <div className="required-msg">REQUIRED FIELDS</div>
              <br />
              <Select
                styles={customStyles}
                options={countryOptions}
                value={selectedCountry}
                onChange={setSelectedCountry}
                placeholder="Please select your country"
                isDisabled={isFormDisabled}
              />
            </li>
            {selectedCountry && (
              <li>
                <Select
                  styles={customStyles}
                  options={stateOptions}
                  value={selectedState}
                  onChange={setSelectedState}
                  placeholder="Please select your state"
                  isDisabled={isFormDisabled}
                />
              </li>
            )}
          </>
          <li>
            <div>
              <input
                type="text"
                placeholder="First Name"
                onChange={(e) => setFullName(e.target.value)}
                disabled={isFormDisabled}
              />{" "}
              <br />
              <br />
              <input
                type="text"
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
                disabled={isFormDisabled}
              />
            </div>
          </li>
          <li>
            <Select
              styles={customStyles}
              options={genderOptions}
              value={selectedGender}
              onChange={setSelectedGender}
              placeholder="Gender"
              isDisabled={isFormDisabled}
            />
          </li>
          <li>
            <div>
              <input
                type="email"
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
                disabled={isFormDisabled}
              />
              <br />
              <br />
              <input
                type="tel"
                placeholder="Mobile Number"
                onChange={(e) => setMobileNumber(e.target.value)}
                disabled={isFormDisabled}
              />
            </div>
          </li>
          <li>
            <div>
              <input
                type="text"
                placeholder="Current or Past Job Title"
                onChange={(e) => setJobTitle(e.target.value)}
                disabled={isFormDisabled}
              />
              <br />
              <br />
              <input
                type="link"
                placeholder="Linkedin Profile"
                onChange={(e) => setLinkedinProfile(e.target.value)}
                disabled={isFormDisabled}
              />
              <br />
              <br />
              <input
                type="text"
                placeholder="GitHub Profile"
                onChange={(e) => setGithubProfile(e.target.value)}
                disabled={isFormDisabled}
              />
            </div>
          </li>
          <li>
            <label>
              Years of Experience <span className="required-star">*</span>
            </label>
            <div className={`radio-group ${isFormDisabled ? "disabled" : ""}`}>
              {["0 - 1", "2 - 5", "6 - 10", "10+"].map((exp, index) => (
                <div
                  className={`radio-item ${
                    selectedExp === exp ? "selected" : ""
                  }`}
                  key={index}
                  onClick={() => handleRadioChange(exp)}
                >
                  <input
                    type="radio"
                    id={`exp${exp}`}
                    name="experience"
                    value={exp}
                    onChange={() => handleRadioChange(exp)}
                    disabled={isFormDisabled}
                  />
                  <label htmlFor={`exp${exp}`}>{exp}</label>
                </div>
              ))}
            </div>
          </li>
          <li>
            <Select
              styles={customStyles}
              options={specializationOptions}
              value={selectedSpecialization}
              onChange={setSelectedSpecialization}
              placeholder="Specialization"
              isDisabled={isFormDisabled}
            />
          </li>
          <div>
            <input
              type="text"
              placeholder="Add Skills - languages/frameworks/tools"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              disabled={isFormDisabled}
            />
            <div className="skills-box">
              {skills.map((skill, index) => (
                <div key={index} className="skill-item">
                  {skill}
                  <span
                    className="remove-skill"
                    onClick={() => removeSkill(index)}
                  >
                    X
                  </span>
                </div>
              ))}
            </div>
          </div>{" "}
          <br />
          <li>
            <textarea
              placeholder="What are you looking to achieve, anything intersting to share?"
              disabled={isFormDisabled}
            ></textarea>
          </li>
          {/*<li>
             <input
              type="checkbox"
              id="terms"
              checked={isChecked}
              onChange={handleCheckboxChange}
            /> 
            <label htmlFor="terms">
            I have read and agreed with{" "}
            <a href="#">the terms and conditions.</a>
          </label>
        </li>*/}
          <li>
            <button type="submit" disabled={isSubmitting || isFormDisabled}>
              {isFormDisabled ? (
                "Submitted"
              ) : isSubmitting ? (
                <ClipLoader color="#ffffff" loading={true} size={24} />
              ) : (
                "SUBMIT"
              )}
            </button>
          </li>
        </ul>
      </form>
      <footer>
        <div className="container"></div>
      </footer>
    </div>
  );
};

export default Enclave;
