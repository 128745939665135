import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD-rrffgyPNrEFXXLYAdmOMphUQFs7ga5A",
  authDomain: "the-enclave-network-apply.firebaseapp.com",
  projectId: "the-enclave-network-apply",
  storageBucket: "the-enclave-network-apply.appspot.com",
  messagingSenderId: "945514187751",
  appId: "1:945514187751:web:bce68c3400f24dffeccb42",
  measurementId: "G-VP74W0LBVJ",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db, analytics };